import fixType from './fixType'

export const siNetwork = fixType({
  prefix: 'fac',
  iconName: 'si-network-icon',
  icon: [
    512,
    512,
    [],
    'e001',
    'M96,192c54.4,0,96-41.6,96-96S150.4,0,96,0,0,41.6,0,96,41.6,192,96,192ZM96,32A64,64,0,1,1,32,96,64.18815,64.18815,0,0,1,96,32ZM416,352c54.4,0,96-41.6,96-96s-41.6-96-96-96-96,41.6-96,96S361.6,352,416,352Zm0-160a64,64,0,1,1-64,64A64.18815,64.18815,0,0,1,416,192ZM96,320c-54.4,0-96,41.6-96,96s41.6,96,96,96,96-41.6,96-96S150.4,320,96,320Zm0,160a64,64,0,1,1,64-64A64.18815,64.18815,0,0,1,96,480Zm0-176a15.99985,15.99985,0,0,1-16-16V224a16,16,0,0,1,32,0v64A15.99985,15.99985,0,0,1,96,304Zm132.30225,64.00293a16.00216,16.00216,0,0,1-8.01514-29.85938l55.42578-32a16,16,0,0,1,16,27.7129l-55.42578,32A15.93372,15.93372,0,0,1,228.30225,368.00293Zm55.3955-172.00049a15.92811,15.92811,0,0,1-7.98486-2.146l-55.42578-32a16,16,0,1,1,16-27.7129l55.42578,32a16.0018,16.0018,0,0,1-8.01514,29.85889Z',
  ],
})
