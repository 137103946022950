import fixType from './fixType'

export const siGeneric = fixType({
  prefix: 'fac',
  iconName: 'si-generic-icon',
  icon: [
    16,
    16,
    [],
    'e001',
    'M7.3 1.2l7.5 2.5-1.7 8.5-8.6 2.6-3.3-6.6 6.1-7M7 0L0 8l4 8 10-3 2-10-9-3z',
  ],
})
