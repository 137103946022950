import fixType from './fixType'

export const siPappLogo = fixType({
  prefix: 'fac',
  iconName: 'si-papp-logo',
  icon: [
    16,
    16,
    [],
    'e001',
    'M6.7561 0.43185L0.367432 7.39976C-0.0189794 7.82855 -0.122022 8.49854 0.161346 9.00774L3.79361 15.8952C4.07698 16.458 4.721 16.726 5.3135 16.5652L13.9949 14.1533C14.4843 14.0193 14.8707 13.5905 14.948 13.0813L16.4679 4.5322C16.5967 3.86221 16.2361 3.21901 15.6178 2.97782L8.14718 0.0834548C7.65773 -0.104143 7.11675 0.0298555 6.7561 0.43185Z',
  ],
})
