import fixType from './fixType'

export const siVideoConsultationPlus = fixType({
  prefix: 'fac',
  iconName: 'si-video-consultation-plus-icon',
  icon: [
    16,
    16,
    [],
    'e001',
    'M17.4625 1.11375C17.1316 0.942375 16.7322 0.965 16.4266 1.17934L13.2141 3.43059C12.9875 3.58878 12.9328 3.90091 13.091 4.12684C13.2219 4.35313 13.5594 4.40625 13.7875 4.25L17 2V9.99687L13.7875 7.75C13.5604 7.59228 13.2494 7.6465 13.0913 7.87306C12.9331 8.09912 12.9878 8.41119 13.2143 8.56931L16.4268 10.8187C16.5987 10.9388 16.7999 10.9995 17.0012 10.9995C17.1589 10.9995 17.3174 10.9619 17.4631 10.8862C17.7937 10.7125 18 10.3469 18 9.97188V2C18 1.625 17.7937 1.2875 17.4625 1.11375ZM10 0H2C0.895313 0 0 0.895313 0 2V10C0 11.1047 0.895313 12 2 12H10C11.1047 12 12 11.1047 12 10V2C12 0.895313 11.1031 0 10 0ZM11 10C11 10.5513 10.5513 11 10 11H2C1.44875 11 1 10.5513 1 10V2C1 1.44875 1.44875 1 2 1H10C10.5513 1 11 1.44875 11 2V10ZM8.5 5.5H6.47188V3.47188C6.47188 3.225 6.275 3 6 3C5.725 3 5.5 3.225 5.5 3.47188V5.5H3.47187C3.225 5.5 3 5.725 3 6C3 6.27606 3.22356 6.47188 3.47187 6.47188H5.5V8.47188C5.5 8.775 5.725 9 6 9C6.27619 9 6.47188 8.77625 6.47188 8.52812V6.47188H8.5C8.775 6.47188 9 6.275 9 6C9 5.725 8.775 5.5 8.5 5.5Z',
  ],
})
