import fixType from './fixType'

export const siPortalPatient = fixType({
  prefix: 'fac',
  iconName: 'si-portal-patient-icon',
  icon: [
    16,
    16,
    [],
    'e001',
    'M3.36364 1.5C3.03864 1.5 2.77273 1.725 2.77273 2V14C2.77273 14.275 3.03864 14.5 3.36364 14.5H11.6364C11.9614 14.5 12.2273 14.275 12.2273 14V2C12.2273 1.725 11.9614 1.5 11.6364 1.5H3.36364ZM1 2C1 0.896875 2.05994 0 3.36364 0H11.6364C12.9401 0 14 0.896875 14 2V14C14 15.1031 12.9401 16 11.6364 16H3.36364C2.05994 16 1 15.1031 1 14V2ZM6.31818 12.5H8.68182C9.00682 12.5 9.27273 12.725 9.27273 13C9.27273 13.275 9.00682 13.5 8.68182 13.5H6.31818C5.99318 13.5 5.72727 13.275 5.72727 13C5.72727 12.725 5.99318 12.5 6.31818 12.5Z M7.56364 8.07273C8.68841 8.07273 9.6 7.16114 9.6 6.03636C9.6 4.91159 8.68841 4 7.56364 4C6.43886 4 5.52727 4.91159 5.52727 6.03636C5.52727 7.16114 6.43886 8.07273 7.56364 8.07273ZM8.98909 8.58182H8.72341C8.37023 8.74409 7.97727 8.83636 7.56364 8.83636C7.15 8.83636 6.75864 8.74409 6.40386 8.58182H6.13818C4.95773 8.58182 4 9.53955 4 10.72V11.3818C4 11.8034 4.34205 12.1455 4.76364 12.1455H10.3636C10.7852 12.1455 11.1273 11.8034 11.1273 11.3818V10.72C11.1273 9.53955 10.1695 8.58182 8.98909 8.58182Z',
  ],
})
