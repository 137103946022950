import fixType from './fixType'

export const siCallCenter = fixType({
  prefix: 'fac',
  iconName: 'si-call-center-icon',
  icon: [
    640,
    576,
    [],
    'e001',
    'M512,320A128,128,0,1,0,640,448,128,128,0,0,0,512,320Zm0,224a96,96,0,1,1,96-96A96.10874,96.10874,0,0,1,512,544Zm15.99976-112.00024L576,431.999v32l-47.99976.00074L528.001,512h-32l-.00074-47.99976L448,464.001v-32l47.99976-.00074L495.999,384h32ZM544,192a80,80,0,1,0-80-80A79.978,79.978,0,0,0,544,192Zm0-128a48,48,0,1,1-48,48A48.01238,48.01238,0,0,1,544,64Zm96,240a16,16,0,0,1-32,0c0-26.5-19.80005-48-44-48H524a39.78676,39.78676,0,0,0-15.69995,3.3,135.53638,135.53638,0,0,0-25.40015-22.4A72.346,72.346,0,0,1,524,224h40C605.90015,224,640,259.9,640,304ZM355.20239,416a160.71727,160.71727,0,0,0-3.20245,31.99316L352,448H176a48.01238,48.01238,0,0,1-48-48V355.2a114.593,114.593,0,0,1,19.6-64.3C168.3,260.2,203.6,244,239.9,244c33.4,0,41.7,12,80.1,12s46.7-12,80.1001-12c35.66943,0,70.363,15.65265,91.19775,45.324a158.95981,158.95981,0,0,0-34.68042,8.51825C442.3623,283.99982,422.11084,276,400.1001,276,372.80005,276,363,288,320,288s-52.8-12-80.2-12c-27.3,0-51.9,12.3-65.7,32.8A83.0798,83.0798,0,0,0,160,355.2V400a16.04705,16.04705,0,0,0,16,16ZM131.7,259.3A39.78854,39.78854,0,0,0,116,256H76c-24.2,0-44,21.5-44,48a16,16,0,0,1-32,0c0-44.1,34.1-80,76-80h40a72.8273,72.8273,0,0,1,41.1,12.9A138.75687,138.75687,0,0,0,131.7,259.3ZM320,224A112,112,0,1,0,208,112,111.94367,111.94367,0,0,0,320,224Zm0-192a80,80,0,1,1-80,80A80.10617,80.10617,0,0,1,320,32ZM96,192a80,80,0,1,0-80-80A79.978,79.978,0,0,0,96,192ZM96,64a48,48,0,1,1-48,48A48.01238,48.01238,0,0,1,96,64Z',
  ],
})
