import fixType from './fixType'

export const siShareIOS = fixType({
  prefix: 'fac',
  iconName: 'si-share-ios',
  icon: [
    512,
    512,
    [],
    'e001',
    'M363.36,123.27a16,16,0,0,1-22.63.09L272,55.19V368a16,16,0,0,1-32,0V54.06l-68.64,69.21a16,16,0,1,1-22.72-22.54l95.21-96a16,16,0,0,1,22.63-.09l96.79,96A16,16,0,0,1,363.36,123.27ZM448,496V208a16,16,0,0,0-16-16H368a16,16,0,0,0,0,32h48V480H96V224h48a16,16,0,0,0,0-32H80a16,16,0,0,0-16,16V496a16,16,0,0,0,16,16H432A16,16,0,0,0,448,496Z',
  ],
})
