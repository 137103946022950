import fixType from './fixType'

export const siCloudCheckmarkSolid = fixType({
  prefix: 'fac',
  iconName: 'si-cloud-checkmark-solid-icon',
  icon: [
    16,
    16,
    [],
    'e001',
    'M8 0c1.15 0 2.15.64687 2.65315 1.59687 1.02812-.31562 2.1906-.06562 3.0031.74688.81248.8125 1.06248 1.97812.7469 3.00312C15.35315 5.85 16 6.85 16 8s-.64685 2.15-1.59685 2.65315c.31558 1.02812.06557 2.1906-.7469 3.0031-.8125.81248-1.97813 1.06248-3.0031.7469C10.15 15.35315 9.15 16 8 16c-1.15 0-2.15-.64685-2.65313-1.59685-1.02812.31558-2.19062.06558-3.00312-.7469-.8125-.8125-1.0625-1.97813-.74688-3.0031C.64687 10.15 0 9.15 0 8c0-1.15.64687-2.15 1.59687-2.65313-.31562-1.02812-.06562-2.19062.74688-3.00312s1.97812-1.0625 3.00312-.74688C5.85.64687 6.85 0 8 0Zm3.53127 6.53127c.29376-.29375.29376-.76877 0-1.0594-.29375-.29062-.76878-.29374-1.0594 0L7.00316 8.94061 5.53438 7.47188c-.29376-.29376-.76876-.29376-1.05938 0s-.29375.76873 0 1.0594l2.00003 2c.2937.29375.76873.29375 1.05933 0z',
  ],
})
