import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'web-streams-polyfill'
import Bluebird from 'bluebird'
import { TextEncoder, TextDecoder } from 'fastestsmallesttextencoderdecoder'

// Polyfill TextEncoder / TextDecoder
if (!window.TextDecoder) window.TextDecoder = TextDecoder
if (!window.TextEncoder) window.TextEncoder = TextEncoder

// We need to correctly configure Bluebird for production (fast, memory-efficient) or development (developer-friendly)
if (process.env.NODE_ENV === 'production') {
  Bluebird.config({
    longStackTraces: false,
    warnings: false,
  })
} else {
  Bluebird.config({
    longStackTraces: true,
    warnings: true,
  })
}

// Don't use a polyfill (which would fall back onto the Browser's implementation), but use bluebird everywhere
global.Promise = Bluebird
