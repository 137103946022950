/**
 * Utility to get matches for navigator.userAgent
 */

import isElectronApp from 'is-electron'

export function isElectron() {
  return isElectronApp
}

export function isElectronV6(): boolean {
  const version = electronVersion()

  return Boolean(version && parseInt(version, 10) >= 6)
}

export function electronVersion() {
  const userAgent = navigator && (navigator.userAgent || '')
  const match = /Electron\/(?<version>[0-9.]+)\s/.exec(userAgent)

  return match && match.groups && match.groups.version
}

export function isIOS() {
  const userAgent = navigator && (navigator.userAgent || '')

  return Boolean(/iPad|iPhone|iPod/.test(userAgent))
}

export function isAndroid() {
  const userAgent = navigator && (navigator.userAgent || '')

  return Boolean(/Android/.test(userAgent))
}

export function isWindows() {
  const userAgent = navigator && (navigator.userAgent || '')

  return Boolean(/Windows/.test(userAgent))
}

export function isSafari() {
  const userAgent = navigator && (navigator.userAgent || '')

  return Boolean(/Safari/.test(userAgent))
}

export function isChrome() {
  const userAgent = navigator && (navigator.userAgent || '')

  return Boolean(/Chrome|CriOS/.test(userAgent))
}

export function isFirefox() {
  const userAgent = navigator && (navigator.userAgent || '')

  return Boolean(/Firefox|FxiOS/.test(userAgent))
}

export function isDesktopApp() {
  const userAgent = navigator && (navigator.userAgent || '')

  return Boolean(/samedi-app|Electron/.test(userAgent))
}

export function isLegacyApp() {
  const userAgent = navigator && (navigator.userAgent || '')

  return Boolean(/samedi-app/.test(userAgent) && !/Electron/.test(userAgent))
}

export function isIE() {
  const userAgent = navigator && (navigator.userAgent || '')

  return Boolean(/Trident/.test(userAgent))
}

export function isEdge() {
  const userAgent = navigator && (navigator.userAgent || '')

  return Boolean(/Edge/.test(userAgent))
}
