import 'polyfills'

import AutoComplete from '@tarekraafat/autocomplete.js'
import jQuery from 'jquery'

import sha2 from 'utils/sha2'

import './add_to_homescreen/AndroidPWABanner'
import './add_to_homescreen/IOSPWATutorial'
import './login.scss'

jQuery(function ($) {
  if (window.siApiUtils && typeof window.siApiUtils.getSavedCredentials === 'function') {
    $('.save-password').show()
    $('#save_password').prop('disabled', false)

    const selectCredentials = function selectCredentials(cred) {
      $('#user_pseudonym').val(cred.pseudonym)
      $('#user_password').val(cred.password)
      $('#save_password').prop('checked', true)
    }

    Promise.resolve(window.siApiUtils.getSavedCredentials()).then((credentials) => {
      if (!credentials) {
        return
      }

      // eslint-disable-next-line no-new
      new AutoComplete({
        selector: '#user_pseudonym',
        data: {
          src: credentials,
          keys: ['pseudonym'],
        },
        resultsList: {
          class: 'login-autocomplete-wrapper',
          tabSelect: true,
          noResults: false,
        },
        resultItem: {
          class: 'login-autocomplete',
          highlight: 'login-autocomplete-highlight',
          selected: 'login-autocomplete-selected',
        },
        events: {
          input: {
            selection: (event) => {
              selectCredentials(event.detail.selection.value)
            },
          },
        },
      })

      if (credentials.length === 1) {
        selectCredentials(credentials[0])
      }
    })
  }

  /**
   * Checks whether that username should be allowed to log in on this computer
   *
   * samedi-app does have a feature that allows an admin to configure which users are allowed
   * to log in from that computer/profile. This is purely an organizational feature, not a security feature.
   * It's configured in app.cfg, see https://repo.samedi.de/samedi-app/latest/configuration/
   *
   * In browsers not supporting that samedi-app API, we just assume that the user is allowed to log in.
   * @param {string} pseudonym
   * @returns {Promise<boolean>}
   */
  async function isUserAllowedToLogin(pseudonym) {
    if (window.siApiUtils && typeof window.siApiUtils.isUserAllowedToLogin === 'function') {
      return window.siApiUtils.isUserAllowedToLogin(pseudonym)
    } else {
      return true
    }
  }

  function saveOrClearPassword() {
    if (!window.siApiUtils) {
      return
    }
    const passwordField = $('#save_password')
    if (passwordField.prop('disabled')) {
      return
    }

    try {
      const pseudonym = $('#user_pseudonym').val()
      const password = $('#user_password').val()

      if (pseudonym && !passwordField.prop('checked')) {
        window.siApiUtils.clearCredentials(pseudonym)
        return
      }

      if (pseudonym && password) {
        window.siApiUtils.saveCredentials(pseudonym, password)
      }
    } catch (e) {
      // Bugsnag!!!
    }
  }

  /**
   * Stores the pwclienthash for use in cryptor
   *
   * This must be called before submit or else the user will get a second password box.
   *
   * @param {string} pseudonym
   * @param {string} pass
   */
  function preSubmit(pseudonym, pass) {
    try {
      const store = window.sessionStorage
      const pwclienthash = sha2.hex_sha256(pseudonym + '-' + pass + '-client.samedi.de')
      store.pwclienthash = pwclienthash
      store.pwpseudonym = pseudonym
      store.login_host = document.location.host
    } catch (e) {
      // might throw QUOTA_EXCEEDED_ERR in private browsing mode
    }

    saveOrClearPassword()
  }

  $('#login_form').on('submit', function () {
    /**
     * @type JQuery<HTMLFormElement>
     */
    const form = $(this)
    const passField = $("input[name='user[password]']", form)
    const pass = passField.val()
    const pseudonym = $("input[name='user[pseudonym]']", form).val().toLowerCase()

    isUserAllowedToLogin(pseudonym).then((allowed) => {
      if (allowed) {
        // HTMLFormElement.submit does not fire `submit` event again!
        preSubmit(pseudonym, pass)
        form[0].submit()
      } else {
        // eslint-disable-next-line no-alert
        alert(
          `Sie dürfen sich von diesem Arbeitsplatz aus nicht mit dem Benutzer "${pseudonym}" in samedi anmelden`
        )
      }
    })

    // stop submit here, we decide inside the promise above if we go on.
    return false
  })
})
