import fixType from './fixType'

export const siFormsAdmin = fixType({
  prefix: 'fac',
  iconName: 'si-forms-admin-icon',
  icon: [
    16,
    16,
    [],
    'e001',
    'M11 6c.3 0 .7 0 1 .1v-2c0-.4-.2-.8-.4-1.1L8.9.4c-.2-.2-.6-.4-1-.4H1.5C.7 0 0 .7 0 1.5v13c0 .8.7 1.5 1.5 1.5H11.1c2.8 0 5-2.2 5-5S13.8 6 11 6zM8 1c.1 0 .2.1.2.1l2.6 2.6c.1.1.2.2.2.3H8V1zM1.5 15c-.3 0-.5-.2-.5-.5v-13c0-.3.2-.5.5-.5H7v3.2c0 .5.3.8.7.8H11v1c-1.1 0-2.1.4-3 1H3.4c-.2 0-.4.2-.4.4v.3c0 .1.2.3.4.3H7c-.2.3-.4.6-.6 1h-3c-.2 0-.4.2-.4.4v.2c0 .2.2.4.4.4h2.7c-.1.3-.1.7-.1 1H3.4c-.2 0-.4.2-.4.4v.2c0 .2.2.4.4.4h2.7c.2 1.2 1 2.3 1.9 3H1.5zm4.9-5.8c-.1.2-.2.5-.3.8.1-.3.2-.6.3-.8zm2.9 6.5zm-.7-.4c.1 0 .1.1.2.1-.1 0-.2 0-.2-.1zM11 15c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zM12.5 10.3v-.8c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5v.7c-.5 0-1 .5-1 1V13c0 .5.5 1 1 1h3c.5 0 1-.5 1-1v-1.8c0-.5-.5-.9-1-.9zm-2-.8c0-.3.2-.5.5-.5s.5.2.5.5v.7h-1v-.7zm2 3.5h-3v-1.8h3V13z',
  ],
})
