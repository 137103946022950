/**
 * We have followed the official documentation for showing pwa banners in android chrome.
 * https://developers.google.com/web/fundamentals/app-install-banners/
 */
import isMobile from 'components/shared/isMobile'

const appInstallField = document.getElementById('addToHomeScreenField')
if (isMobile() && appInstallField) {
  registerServiceWorker()
  showAddToHomeScreenBanner()
}

function registerServiceWorker() {
  if (!navigator.serviceWorker) {
    return
  }
  if (!navigator.serviceWorker.controller) {
    navigator.serviceWorker.register('serviceWorker.js', { scope: './' })
  }
}

function showAddToHomeScreenBanner() {
  let deferredPrompt

  window.addEventListener(
    'beforeinstallprompt',
    (e) => {
      e.preventDefault()
      deferredPrompt = e
      showAddToHomeScreenPrompt()
    },
    false
  )

  function showAddToHomeScreenPrompt() {
    const btnAdd = document.getElementById('addToHomeScreenButton')

    appInstallField.style.display = 'block'
    btnAdd.addEventListener('click', dismissAddToHomeScreenBanner)
  }

  function dismissAddToHomeScreenBanner() {
    appInstallField.style.display = 'none'
    deferredPrompt.prompt()

    deferredPrompt.userChoice.then(() => {
      deferredPrompt = null
    })
  }
}
