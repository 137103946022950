// ("#000000", 90) => "rgba(0, 0, 0, 0.9)"
export const hexWithOpacity = (hexCode: string, opacity: number) => {
  const opacityString = (opacity / 100).toFixed(3)

  const red = parseInt(hexCode.substring(1, 3), 16)
  const green = parseInt(hexCode.substring(3, 5), 16)
  const blue = parseInt(hexCode.substring(5), 16)

  return `rgba(${red}, ${green}, ${blue}, ${opacityString})`
}

export const DEFAULT_FONT_FAMILY = 'arial, tahoma, helvetica, sans-serif'
export const BREAKPOINT_MOBILE_MAX_WIDTH = 752
export const BREAKPOINT_MEDIUM_MAX_WIDTH = 1023
export const BREAKPOINT_EXTRA_LARGE_MAX_WIDTH = 1279
export const BREAKPOINT_XX_LARGE_MAX_WIDTH = 1679
export const WINDOW_SCALE_ON_MOBILE_FOR_NON_RESPONSIVE_MODULES = 0.5
export const NAVBAR_SCALE_ON_MOBILE_FOR_NON_RESPONSIVE_MODULES = 2
export const NAVBAR_HEIGHT = 50
export const FOOTER_HEIGHT = 32
export const BOTTOM_BAR_HEIGHT = 49
export const NAVBAR_MOBILE_VERSION_TOOLTIP_Z_INDEX = 1
export const OVERLAY_Z_INDEX = 10000 // in order to not interfere with calendar :(
export const FOOTER_Z_INDEX = 1
export const FOOTER_TAB_Z_INDEX_WHEN_OPEN_ON_MOBILE = 2
export const IOSBANNER_Z_INDEX = 3
export const TOASTBAR_Z_INDEX = 2
export const MODAL_Z_INDEX = 10
export const MODAL_X_POSITION = 80 // modals are displayed 80px below the top of the screen

export const SPACING = 8

/* Colors */
export const LIGHT_BABY_BLUE = '#f0f9fc'
export const DARK_BABY_BLUE = '#99bbe8'
export const GREYED_BABY_BLUE = '#c8cfdb'
export const NAVY_BLUE = '#104080'
export const MID_BLUE = '#0067b2'
export const OVERLAY_MID_BLUE = 'rgba(0, 103, 178, 0.65)'
export const MAGENTA = '#e61657'
export const LIGHT_MID_BLUE = '#e6f0f8'
export const SKY_BLUE = '#00a3e2'
export const SKY_BLUE_A30 = 'rgba(0, 163, 226, 0.3)'
export const BLACK = '#000000'
export const GREY_30 = '#4d4d4d'
export const GREY_40 = '#666666'
export const GREY_50 = '#808080'
export const GREY_60 = '#999999'
export const GREY_65 = '#a6a6a6'
export const GREY_70 = '#b3b3b3'
export const GREY_80 = '#cccccc'
export const GREY_85 = '#d9d9d9'
export const GREY_90 = '#e6e6e6'
export const GREY_95 = '#f2f2f2'
export const GREY_96 = '#f5f5f5'
export const GREY_98 = '#fafafa'
export const WHITE = '#ffffff'
export const OFF_WHITE = '#fcf8e3'
export const RED = '#ff0000'
export const RED_ERROR = '#ff0000'
export const RED_ERROR_LIGHT = '#ff5b4f'
export const RED_ERROR_LIGHT_BACKGROUND = '#fce9e9'
export const PALE_RED = '#f2dede'
export const GREEN = '#009900'
export const BOLD_GREEN = '#13ab3d'
export const VERY_LIGHT_GREEN = '#dff0d8'
export const PALE_BLUE = '#d9edf7'
export const BLACKOUT_COLOR = 'rgba(229, 229, 229, 0.8)'
export const YELLOW_WARNING = '#f8c300'
export const PALE_YELLOW = '#fefcab'

/* Typography */
export const STYLE_HEADING_1 = `
  font-size: 25px;
  line-height: 32px;
  font-weight: normal;
`

export const STYLE_HEADING_2 = `
  font-size: 22px;
  line-height: 24px;
  font-weight: bold;
`

export const STYLE_HEADING_3 = `
  font-size: 19px;
  line-height: 24px;
  font-weight: bold;
  color: ${NAVY_BLUE};
`

export const STYLE_HEADING_4 = `
  font-size: 17px;
  line-height: 24px;
  font-weight: bold;
`

export const STYLE_HEADING_5 = `
  font-size: 15px;
  line-height: 24px;
  font-weight: bold;
`

export const STYLE_HEADING_6 = `
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
`

export const STYLE_HEADING_7 = `
  font-size: 11px;
  line-height: 16px;
  font-weight: bold;
`

export const STYLE_BUTTON_TEXT = `
  font-size: 14px;
  line-height: 16px;
  font-weight: bold;
`

export const STYLE_LABEL_TEXT = `
  font-size: 11px;
  line-height: 16px;
  font-weight: bold;
`

export const STYLE_ERROR_TEXT = `
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  color: ${RED_ERROR};
`

export const STYLE_DEFAULT_TEXT = `
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
`

export const STYLE_NORMAL_TEXT_BOLD = `
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`

export const STYLE_TOOLTIP = `
  opacity: 0;
  position: absolute;
  transition: opacity 0.5s;
  visibility: hidden;
  z-index: 2;
`

export const STYLE_TOOLTIP_HOVER = `
  opacity: 1;
  visibility: initial;
`

/* Utils */

export const SHOW_ELLIPSIS_WHEN_TEXT_OVERFLOWS = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

/* Shapes & Effects */
export const SHAPE_SOFT = `
  border-radius: 3px;
`

export const SHAPE_SOFTER_RADIUS = '5px'
export const SHAPE_SOFTER = `
  border-radius: 5px;
`

export const EFFECT_SHADOW_100 = `
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.08);
`

export const EFFECT_SHADOW_200 = `
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
`

export const EFFECT_SHADOW_300 = `
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
`

export const NON_RECT_EFFECT_SHADOW_300 = `
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.16));
`

export const EFFECT_SHADOW_400 = `
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.24);
`

export const INNER_SHADOW_100 = `
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.08);
`

/* Special */

export const SCROLLBAR_STYLE = `
  &::-webkit-scrollbar {
    background: ${WHITE};
    width: 14px;
    border-left: 1px solid ${GREY_96};
    border-right: 1px solid ${GREY_96};
  }

  &::-webkit-scrollbar-track {
    box-shadow: 0;
  }

  &::-webkit-scrollbar-thumb {
    ${SHAPE_SOFT}
    background: ${GREY_90};
    /* needed to set the width */
    border: 1px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    background-clip: content-box;

    &:hover {
      ${SHAPE_SOFT}
      background: ${GREY_80};
      /* needed to set the width */
      border: 1px solid transparent;
      border-left: 2px solid transparent;
      border-right: 2px solid transparent;
      background-clip: content-box;
    }
  }
`
