import fixType from './fixType'

export const siSurveyLight = fixType({
  prefix: 'fac',
  iconName: 'si-survey-light-icon',
  icon: [
    384,
    512,
    [],
    'e001',
    'M336,64H247.4a52.868,52.868,0,0,0,.6-8,56,56,0,0,0-112,0,52.86673,52.86673,0,0,0,.6,8H48A48.01238,48.01238,0,0,0,0,112V464a48.01238,48.01238,0,0,0,48,48H336a48.01238,48.01238,0,0,0,48-48V112A48.01238,48.01238,0,0,0,336,64ZM192,32a24,24,0,1,1-24,24A23.94241,23.94241,0,0,1,192,32ZM352,464a16.04705,16.04705,0,0,1-16,16H48a16.047,16.047,0,0,1-16-16V112A16.047,16.047,0,0,1,48,96H96v20a12.03528,12.03528,0,0,0,12,12H276a12.03527,12.03527,0,0,0,12-12V96h48a16.047,16.047,0,0,1,16,16ZM207.20018,240.78815l56.00729,56.0073L141.59,418.41289l-49.935,5.51236A10.50106,10.50106,0,0,1,80.066,412.32743l5.5561-49.96995Zm90.64764-8.33853-26.29744-26.29744a21.01028,21.01028,0,0,0-29.70985,0l-24.74,24.74,56.00728,56.00729,24.74-24.74A21.01028,21.01028,0,0,0,297.84782,232.44962Z',
  ],
})
