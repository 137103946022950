import fixType from './fixType'

export const siCloudExclamation = fixType({
  prefix: 'fac',
  iconName: 'si-cloud-exclamation-icon',
  icon: [
    16,
    16,
    [],
    'e001',
    'M8.75 4.25c0-.4156-.3344-.75-.75-.75s-.75.3344-.75.75v4.5c0 .4156.3344.75.75.75s.75-.3344.75-.75ZM8 12.5a1.00007 1.00007 0 1 0 0-2.00013A1.00007 1.00007 0 0 0 8 12.5Zm0-11c-.75313 0-1.40933.42187-1.74067 1.04687a.75033.75033 0 0 1-.88433.36566c-.675-.20626-1.44067-.0438-1.97187.4906A1.9762 1.9762 0 0 0 2.91247 5.375c.10626.34373-.04687.7156-.3656.88127C1.92187 6.5906 1.5 7.24687 1.5 8s.42187 1.4094 1.04687 1.7406c.31873.1688.47186.54067.36566.8844-.20626.675-.0438 1.4406.4906 1.97187a1.97613 1.97613 0 0 0 1.97187.4906c.34373-.1062.7156.04693.88127.36566.33126.625.98746 1.04687 1.7406 1.04687.75313 0 1.4094-.42187 1.74066-1.04687a.7464.7464 0 0 1 .8812-.36566c.675.20626 1.44067.0438 1.97187-.4906a1.97627 1.97627 0 0 0 .49067-1.97187c-.10627-.34373.04687-.7156.3656-.88127.625-.33126 1.04686-.98746 1.04686-1.7406 0-.75313-.42186-1.40933-1.04686-1.74066a.74633.74633 0 0 1-.3656-.8812c.2062-.675.04373-1.44067-.49067-1.97187-.53433-.53133-1.2906-.70313-1.9656-.49687a.7504.7504 0 0 1-.8844-.36566C9.4094 1.92187 8.75313 1.5 8 1.5Zm-2.75313-.14373C5.88133.53127 6.87813 0 8 0c1.12187 0 2.11873.53127 2.75313 1.35627 1.03127-.1344 2.11247.19373 2.90627.98746.79373.7938 1.12187 1.875.98747 2.90627C15.46873 5.88127 16 6.87813 16 8s-.53127 2.11873-1.35627 2.75313c.1344 1.03127-.19373 2.11247-.98746 2.90627-.79374.79373-1.875 1.12187-2.90627.98747C10.11873 15.46873 9.12187 16 8 16s-2.11873-.53127-2.75313-1.35627c-1.03127.1344-2.11247-.19373-2.90627-.98746-.79373-.79374-1.12187-1.875-.98747-2.90627C.53127 10.11873 0 9.12187 0 8s.53127-2.11873 1.35627-2.75313C1.22187 4.2156 1.55 3.1344 2.34373 2.3406c.79374-.79373 1.875-1.12187 2.90627-.98747Z',
  ],
})
