import fixType from './fixType'

export const siNotificationsNetwork = fixType({
  prefix: 'fac',
  iconName: 'si-notifications-network-icon',
  icon: [
    512,
    512,
    [],
    'e001',
    'M96 192c54.4 0 96-41.6 96-96S150.4 0 96 0 0 41.6 0 96s41.6 96 96 96zm0-160c35.2 0 64 28.8 64 64s-28.8 64-64 64-64-28.8-64-64 28.8-64 64-64zm0 288c-54.4 0-96 41.6-96 96s41.6 96 96 96 96-41.6 96-96-41.6-96-96-96zm0 160c-35.2 0-64-28.8-64-64s28.8-64 64-64 64 28.8 64 64-28.8 64-64 64zm0-176c-8.8 0-16-7.2-16-16v-64c0-8.8 7.2-16 16-16s16 7.2 16 16v64c0 8.8-7.2 16-16 16zm132.3 64c-5.5 0-10.9-2.9-13.9-8-4.4-7.7-1.8-17.4 5.9-21.9l55.4-32c7.7-4.4 17.4-1.8 21.9 5.9s1.8 17.4-5.9 21.9l-55.4 32c-2.5 1.4-5.3 2.1-8 2.1zm55.4-172c-2.7 0-5.5-.7-8-2.1l-55.4-32c-7.7-4.4-10.3-14.2-5.9-21.9 4.4-7.7 14.2-10.3 21.9-5.9l55.4 32c7.7 4.4 10.3 14.2 5.9 21.9-3 5.1-8.4 8-13.9 8zM512 248v16c0 4.4-3.6 8-8 8h-72v72c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8v-72h-72c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h72v-72c0-4.4 3.6-8 8-8h16c4.4 0 8 3.6 8 8v72h72c4.4 0 8 3.6 8 8z',
  ],
})
