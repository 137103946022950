import fixType from './fixType'

export const siChainAppointmentDuoTone = fixType({
  prefix: 'fac',
  iconName: 'si-chain-appointment-icon',
  icon: [
    448,
    512,
    [],
    'f133',
    [
      'M0 192V464a48 48 0 0 0 48 48H400a48 48 0 0 0 48-48h0V192Zm304-64h32a16 16 0 0 0 16-16V16A16 16 0 0 0 336 0H304a16 16 0 0 0-16 16v96A16 16 0 0 0 304 128Zm-192 0h32a16 16 0 0 0 16-16V16A16 16 0 0 0 144 0H112A16 16 0 0 0 96 16v96A16 16 0 0 0 112 128Z',
      'M448 112v80H0V112A48 48 0 0 1 48 64H96v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64H288v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h48A48 48 0 0 1 448 112ZM281.3 320a79.37 79.37 0 0 1 6.7 32 80.24 80.24 0 0 1-80 80H144a80 80 0 0 1 0-160h32.1a81.19 81.19 0 0 0-25.4 32H144a48 48 0 0 0 0 96h64a47.86 47.86 0 0 0 35.6-80Zm-76.9 64A47.95 47.95 0 0 1 240 304h64a48 48 0 0 1 0 96h-6.7a81.19 81.19 0 0 1-25.4 32H304a80 80 0 0 0 0-160H240a80.24 80.24 0 0 0-80 80 79.37 79.37 0 0 0 6.7 32Z',
    ],
  ],
})
