import fixType from './fixType'

export const siReferralAppointment = fixType({
  prefix: 'fac',
  iconName: 'si-referral-appointment-icon',
  icon: [
    512,
    512,
    [],
    'e001',
    'M432,64H384V12A12.00052,12.00052,0,0,0,372,0h-8a12.00052,12.00052,0,0,0-12,12V64H160V12A12.00052,12.00052,0,0,0,148,0h-8a12.00052,12.00052,0,0,0-12,12V64H80a47.99956,47.99956,0,0,0-48,48V464a47.99956,47.99956,0,0,0,48,48H432a47.99956,47.99956,0,0,0,48-48V112A47.99956,47.99956,0,0,0,432,64Zm16,400a16.01869,16.01869,0,0,1-16,16H80a16.01869,16.01869,0,0,1-16-16V192H448Zm0-304H64V112A16.01865,16.01865,0,0,1,80,96H432a16.01865,16.01865,0,0,1,16,16ZM411.31348,283.31348l-54.34314,54.34314a8,8,0,0,1-11.31372,0l-11.3133-11.3133a8,8,0,0,1-.00006-11.31366L361.37238,288H104a8.02353,8.02353,0,0,1-8-8V264a8.02349,8.02349,0,0,1,8-8H361.37225l-27.029-27.0293a8.00008,8.00008,0,0,1-.00012-11.3136l11.31354-11.314a8,8,0,0,1,11.31384-.00012l54.343,54.3435A15.99831,15.99831,0,0,1,411.31348,283.31348ZM100.68677,388.68638l54.34314-54.34314a8,8,0,0,1,11.31372,0l11.31329,11.31329a8,8,0,0,1,.00006,11.31366l-27.02911,27.02967H408.00024a8.02352,8.02352,0,0,1,8,8v16a8.02349,8.02349,0,0,1-8,8H150.628l27.029,27.02929a8.00007,8.00007,0,0,1,.00013,11.3136l-11.31354,11.314a8,8,0,0,1-11.31384.00012l-54.343-54.34351A15.99828,15.99828,0,0,1,100.68677,388.68638Z',
  ],
})
