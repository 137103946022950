import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

interface SiIconDefinition {
  prefix: 'fac'
  iconName: string
  icon: IconDefinition['icon']
}

/**
 * font-awesome supports additional custom icons only for Pro Service accounts
 * and on the `fak`-layer. But we can and do abuse what font-awesome did for
 * `findIconDefinition` to hand in the samedi icons. Those have to be typed as
 * fa-icons though. In order to use our custom prefix (fac) and own names,
 * double-cast the js-object without changing it - widening to anything then
 * narrowing down to the expected valid type.
 *
 * @param icon own icon definition
 * @returns the first argument, typed differently
 */
export default function fixType(icon: SiIconDefinition): IconDefinition {
  return icon as unknown as IconDefinition
}
