import fixType from './fixType'

export const siChainAppointment = fixType({
  prefix: 'fac',
  iconName: 'si-chain-appointment-icon',
  icon: [
    512,
    512,
    [],
    'e001',
    'M431,64H383V12A12.00052,12.00052,0,0,0,371,0h-8a12.00052,12.00052,0,0,0-12,12V64H159V12A12.00052,12.00052,0,0,0,147,0h-8a12.00052,12.00052,0,0,0-12,12V64H79a47.99956,47.99956,0,0,0-48,48V464a47.99956,47.99956,0,0,0,48,48H431a47.99956,47.99956,0,0,0,48-48V112A47.99956,47.99956,0,0,0,431,64Zm16,400a16.01869,16.01869,0,0,1-16,16H79a16.01869,16.01869,0,0,1-16-16V192H447Zm0-304H63V112A16.01865,16.01865,0,0,1,79,96H431a16.01865,16.01865,0,0,1,16,16ZM313.262,304A79.23283,79.23283,0,0,1,320,335.99988v.00024A80.23514,80.23514,0,0,1,240.00012,416H175.99988A80.23514,80.23514,0,0,1,96,336.00012v-.00024A80.23514,80.23514,0,0,1,175.99988,256h32.05554a80.46362,80.46362,0,0,0-25.35565,32h-6.69989a48.0002,48.0002,0,0,0,0,96h64.00024a47.89423,47.89423,0,0,0,35.64545-80ZM198.738,368A79.23283,79.23283,0,0,1,192,336.00012v-.00024A80.23514,80.23514,0,0,1,271.99988,256h64.00024A80.23514,80.23514,0,0,1,416,335.99988v.00024A80.23514,80.23514,0,0,1,336.00012,416H303.94458a80.46362,80.46362,0,0,0,25.35565-32h6.69989a48.0002,48.0002,0,0,0,0-96H271.99988a47.89423,47.89423,0,0,0-35.64545,80Z',
  ],
})
