import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { renderToString } from 'react-dom/server'

import { siShareIOS } from 'components/shared/Icons'
import isStandalone from 'services/isStandalone'
import { isIOS, isSafari } from 'services/userAgentMatcher'

if (isIOS() && isSafari() && !isStandalone() && document.getElementById('addToHomeScreenIOS')) {
  const container = document.getElementById('addToHomeScreenIOS')

  // Render custom icon
  if (container.querySelector('.si-icon-share-ios')) {
    container.querySelector('.si-icon-share-ios').innerHTML = renderToString(
      <FontAwesomeIcon icon={siShareIOS} />
    )
  }

  // Show Banner
  container.style.display = 'block'
}
