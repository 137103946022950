import fixType from './fixType'

export const siSurveySolid = fixType({
  prefix: 'fac',
  iconName: 'si-survey-solid-icon',
  icon: [
    384,
    512,
    [],
    'e001',
    'M336,64H256a64,64,0,0,0-128,0H48A47.99956,47.99956,0,0,0,0,112V464a47.99956,47.99956,0,0,0,48,48H336a47.99956,47.99956,0,0,0,48-48V112A47.99956,47.99956,0,0,0,336,64ZM192,40a24,24,0,1,1-24,24A23.99979,23.99979,0,0,1,192,40ZM96,134a6,6,0,0,1,6-6H282a6,6,0,0,1,6,6v20a6,6,0,0,1-6,6H102a6,6,0,0,1-6-6Zm45.59009,284.4129L91.655,423.92523a10.50115,10.50115,0,0,1-11.58911-11.59784l5.55615-49.96991L207.2002,240.78815l56.00732,56.00726ZM297.8479,262.15948l-24.74,24.73993-56.00732-56.00726,24.74-24.74a21.01032,21.01032,0,0,1,29.70984,0l26.29748,26.29742A21.01039,21.01039,0,0,1,297.8479,262.15948Z',
  ],
})
